import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Row, Col } from 'antd';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
  const post = data.ghostPost
  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Layout>
        <div className="post-container">
          <Row>
            <Col>
              <h1 className="content-title">{post.title}</h1>
              {post.feature_image ?
                <figure className="post-feature-image">
                  <img src={post.feature_image} alt={post.title} />
                </figure> : null}
            </Col>
            <Col>
              <div className="post-full-content">
                <section className="content-body load-external-scripts"
                  dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
            </Col>
          </Row>
          {/* <article className="content">
                            { post.feature_image ?
                                <figure className="post-feature-image">
                                    <img src={ post.feature_image } alt={ post.title } />
                                </figure> : null }
                            <section className="post-full-content">
                                <h1 className="content-title">{post.title}</h1>
                                <section className="content-body load-external-scripts"
                                    dangerouslySetInnerHTML={{ __html: post.html }} />
                            </section>
                        </article> */}
        </div>
      </Layout>
    </>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
